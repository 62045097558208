import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AppState } from '@/vuex/store';
import {
  applyCommandToAnswer,
  applyFileToReport,
  emptyAnswer,
} from '@/datatypes/Commands';
import { poke } from '@/BackgroundSync';
import { markFileDeleted, storeFile } from '@/sync/FilesDb';
import { FilesTable } from '@/sync/Dexie';
import { AnswerCommand, Command } from '@/datatypes/CommandTypes';
import { sectionList } from '@/ReportSections';
import { validateAnswer } from '@/answers/validate';
import {
  QuestionRuleData,
  QuestionRules,
  QuestionType,
} from '@/answers/RuleTypes';
import { GqlQuestion } from '@/gql/AllInspections';
import { GqlAllMyAnswers, GqlAllMyReports } from '@/gql/AllMyReports';

const genUuid = require('uuid-random') as () => string; // tslint:disable-line:no-var-requires

export const ANSWER_UPDATE = 'ANSWER_UPDATE';
export const REPORT_ANSWER_UPDATE = 'REPORT_ANSWER_UPDATE';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const APPLY_COMMAND = 'APPLY_COMMAND';

export const ANSWER_ADD_FILE = 'ANSWER_ADD_FILE';
export const ANSWER_DEL_FILE = 'ANSWER_DEL_FILE';

export interface AnswerImageFile {
  reportId: string;
  questionId: string;
  name: string;
  type: string;
  data: Blob;
}

export const actions: ActionTree<AppState, AppState> = {
  applyCommand({ commit, state }, command: Command) {
    if (
      state.report &&
      command.cmd !== 'UpdateInspectReport' &&
      command.cmd !== 'CreateInspectReport' &&
      command.inspectId === state.report.uuid
    ) {
      commit(APPLY_COMMAND, command);
    }
  },
  answerAddImage({ commit }, info: FilesTable) {
    const fileUuid = genUuid();
    storeFile(
      info.reportId,
      info.questionId,
      info.uuid,
      info.name,
      info.type,
      info.data
    ).then(() => {
      commit(ANSWER_ADD_FILE, info);
      poke();
    });
  },
  async answerDeleteFile({ commit }, file: FilesTable) {
    if (file) {
      await markFileDeleted(file.uuid);
      commit(ANSWER_DEL_FILE, file);
      poke();
    }
  },
};

export const mutations: MutationTree<AppState> = {
  [APPLY_COMMAND](state: AppState, command: AnswerCommand) {
    if (!state.report || !state.inspection) {
      throw new Error('Unable to update answer when no report is selected');
    }
    const question = state.inspection.questions.find(
      (q) => q.uuid === command.questionId
    );
    if (!question) {
      throw new Error('Question ID is invalid');
    }
    let answer = state.report.answers.find(
      (x) => x.inanswerquestion === command.questionId
    );
    if (!answer) {
      answer = emptyAnswer(question.uuid);
      state.report.answers.push(answer);
    }
    applyCommandToAnswer(command, answer);
  },
  [ANSWER_ADD_FILE](state: AppState, file: FilesTable) {
    if (!state.report) {
      throw new Error('Unable to add file when no report is selected');
    }
    applyFileToReport(file, state.report);
  },
  [ANSWER_DEL_FILE](state: AppState, file: FilesTable) {
    if (!state.report) {
      throw new Error('Unable to delete file when no report is selected');
    }
    state.report.answers.forEach((ans) => {
      let idx = -1;
      do {
        const obj =
          file.type.split('/')[0] === 'image' ? ans.images : ans.files;
        idx = obj.findIndex((i) => i.uuid === file.uuid);
        if (idx >= 0) {
          obj.splice(idx, 1);
        }
      } while (idx >= 0);
    });
  },
};

export interface QAValidate {
  complete: boolean;
  valid: boolean;
  error: string;
  errorList: string[];
}

export interface ReportElement {
  name: string;
  number: number;
  icon: string;
  noQuestion: number;
  noComplete: number;
  progress: number;
}

function normalizeRules(rule: QuestionRuleData): QuestionRules {
  return {
    unit: rule.unit ?? '',
    alert: rule.alert ?? null,
    show: rule.show ?? [],
    field: rule.field ?? '',
    range: rule.range ?? null,
    choice: rule.choice ?? [],
    table: rule.table ?? [],
  };
}

export interface QuestionAnswerState {
  element: number;
  number: number;
  question: GqlQuestion;
  answer: GqlAllMyAnswers | null;
  rules: QuestionRuleData;
  valid: QAValidate;
  visible: boolean | null;
  value: string | Record<string, any>;
}

function parseElement(question: GqlQuestion): number {
  const secNum = parseInt(question.inquestionsection, 10);
  return isNaN(secNum) ? 1 : secNum;
}
function parseNumber(question: GqlQuestion): number {
  const qNum = parseInt(question.inquestionnumber, 10);
  return isNaN(qNum) ? 1 : qNum;
}

function parseRules(question: GqlQuestion): QuestionRules {
  let rule: QuestionRuleData = {};
  try {
    if (question.inquestionrules) {
      rule = JSON.parse(question.inquestionrules) ?? {};
    }
  } catch (e) {
    // Cant parse rules
  }
  return {
    unit: rule.unit ?? '',
    alert: rule.alert ?? null,
    show: rule.show ?? [],
    field: rule.field ?? '',
    range: rule.range ?? null,
    choice: rule.choice ?? [],
    table: rule.table ?? [],
  };
}

function parseValue(
  question: GqlQuestion,
  answer: GqlAllMyAnswers | null
): any {
  const t = question.inquestiontype;
  if (t === QuestionType.YNNN) {
    if (!answer) {
      return '';
    }
    return answer.code;
  }
  const JSON_ANSWERS = [
    QuestionType.TableUpload,
    QuestionType.TableYesNo,
    QuestionType.TableNumber,
    QuestionType.TableDecimal,
    QuestionType.TableDropdown,
    QuestionType.Hatches,
    QuestionType.MoorLines,
  ];
  if (JSON_ANSWERS.includes(t)) {
    if (!answer) {
      return {};
    }
    try {
      return JSON.parse(answer.inanswervalue);
    } catch (e) {
      return {};
    }
  }
  if (!answer) {
    return '';
  }
  return answer.inanswervalue;
}

function getVisible(report: GqlAllMyReports, rules: QuestionRules): boolean {
  for (const s of rules.show) {
    if (s.source === 'fuel') {
      return s.values.includes(report.inreportvesselfuel);
    } else if (s.source === 'dwt' || s.source === 'winch') {
      let v = 0;
      if (s.source === 'dwt') {
        v = parseInt(report.inreportvesseldwt, 10);
      }
      if (s.source === 'winch') {
        v = parseInt(report.inreportvesselwinches, 10);
      }
      if (isNaN(v)) {
        return null;
      } else {
        return (s.low === null || s.low < v) && (s.high === null || s.high > v);
      }
    }
  }
  return true;
}

export const getters: GetterTree<AppState, AppState> = {
  qa(state: AppState): QuestionAnswerState[] {
    const out: QuestionAnswerState[] = [];

    for (const question of state.inspection?.questions ?? []) {
      const answer = state.report.answers.find(
        (a) => a.inanswerquestion === question.uuid
      );
      const rules = parseRules(question);
      out.push({
        element: parseElement(question),
        number: parseNumber(question),
        question,
        answer,
        rules,
        valid: validateAnswer(question, answer, rules, state.report),
        visible: getVisible(state.report, rules),
        value: parseValue(question, answer),
      });
    }
    out.sort(
      (a, b) => a.element * 1000 + a.number - (b.element * 1000 + b.number)
    );
    return out;
  },

  reportElements(state: AppState, vuexGet): ReportElement[] {
    const out = [];
    const review = {
      name: 'Requires Review',
      number: -1,
      icon: 'triangle-exclamation',
      noQuestion: 0,
      noComplete: 0,
      progress: 0,
    };
    for (const qa of vuexGet.qa as QuestionAnswerState[]) {
      const elNum = qa.element;
      out[elNum] ??= {
        name: '',
        number: elNum,
        icon: '',
        noQuestion: 0,
        noComplete: 0,
        progress: 0,
      };
      if (qa.visible !== false) {
        out[elNum].noQuestion++;
        if (qa.valid.complete) {
          out[elNum].noComplete++;
        }
        if (
          qa.answer?.inanswerreviewstatus === 0x22 ||
          qa.answer?.inanswerreviewstatus === 0x02
        ) {
          review.noQuestion += 1;
          if (qa.valid.complete) {
            review.noComplete += 1;
          }
        }
      }
    }
    try {
      const sections = JSON.parse(
        state.inspection?.prop_inspectelements ?? '[]'
      )?.sections;
      if (Array.isArray(sections)) {
        for (const s of sections) {
          out[s.number] ??= {
            name: s.name ?? '',
            number: s.number,
            icon: s.icon ?? '',
            noQuestion: 0,
            noComplete: 0,
            progress: 0,
          };
          out[s.number].name = s.name;
          out[s.number].icon = s.icon;
        }
      }
    } catch (e) {
      // console.error('Could not decode element names', e);
    }
    for (const s of out) {
      if (s) {
        if (s.name === '') {
          s.name = sectionList.find((i) => i.number === s.number)?.name ?? '';
        }
        if (s.icon === '') {
          s.icon = sectionList.find((i) => i.number === s.number)?.icon ?? '';
        }
        if (s.noQuestion > 0) {
          s.progress = s.noComplete / s.noQuestion;
        }
      }
    }
    const list = out.filter((v) => !!v);
    if (review.noQuestion > 0) {
      review.progress = review.noComplete / review.noQuestion;
      return [review].concat(list);
    } else {
      return list;
    }
  },
};
